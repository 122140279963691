import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from './layout';
import Link from '../Links';

import './account.css';

export default function FooterAccount() {
  const data = useStaticQuery(graphql`
    query FooterAccountMenuQuery {
      wpMenu(databaseId: { eq: 80 }) {
        ...MenuData
      }
    }
  `);
  const items = data.wpMenu.menuItems.nodes;
  return (
    <Layout
      title="My <span>account</span>"
      className="footerAccount"
      style={{ marginLeft: `auto` }}
    >
      <ul className="accountMenu">
        {items.map((item, i) => {
          const key = `accountMenu${i}`;
          return (
            <li key={key} style={{ marginTop: i !== 0 && `3px` }}>
              <Link to={item.url}>{item.label}</Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
}
