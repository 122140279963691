import React from 'react';
import parse from 'html-react-parser';

import Col from '../Container/column';

export default function Layout({ className, title, children, style }) {
  return (
    <Col className={className} md={{ width: `auto` }} style={style}>
      <h2 style={titleStyle}>{parse(title)}</h2>
      {children}
    </Col>
  );
}

const titleStyle = {
  textTransform: `uppercase`,
  fontSize: `30px`,
};
